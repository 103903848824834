import * as React from 'react'
import { Link } from 'react-router-dom'

export default function Privacidade() {
  return (
    <div>
      <p
        style={{
          fontFamily: 'Barlow',
          fontSize: 15,
          textDecoration: 'none',
          margin: 10,
          color: '#FFF',
          fontWeight: '600'
        }}
        href=""
      >
        Womp Play. Todos os direitos reservados &copy;
        {new Date().getFullYear()} &nbsp;&nbsp;&nbsp;{'|'}
        <a
          style={{
            fontFamily: 'Barlow',
            fontSize: 15,
            padding: '1%',
            textDecoration: 'none',
            color: '#FFF',
            fontWeight: '800'
          }}
          href="/termos-de-uso"
          target="_blank"
        >
          Termos de Uso
        </a>
        <a
          style={{
            fontFamily: 'Barlow',
            fontSize: 15,
            padding: '1%',
            textDecoration: 'none',
            color: '#FFF',
            fontWeight: '800'
          }}
          href="/privacidade"
          target="_blank"
        >
          Privacidade
        </a>
        <Link
          to="/ajuda"
          style={{
            textDecoration: 'none' // Remova essa linha para remover o sublinhado
          }}
        >
          <a
            style={{
              fontFamily: 'Barlow',
              fontSize: 15,
              padding: '1%',
              textDecoration: 'none',
              color: '#FFF',
              fontWeight: '800'
            }}
            href="/ajuda"
            target="_blank"
          >
            Ajuda
          </a>
        </Link>
        <a
          style={{
            fontFamily: 'Barlow',
            fontSize: 15,
            padding: '1%',
            textDecoration: 'none',
            color: '#FFF',
            fontWeight: '800'
          }}
          href="/cookies"
          target="_blank"
        >
          Cookies
        </a>
      </p>
    </div>
  )
}
