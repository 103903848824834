import React from 'react'
import Header from '../Components/Header/Header.js'
import Questions from '../Components/Questions/Questions.js'
import Footer from '../Components/Footer/Footer.js'

import Star from '../Components/Images/star_439x264.png'
import Full from '../Components/Images/full_439x250.png'

import tvencontrodasaguas from '../Components/Images/Channels/tvencontrodasaguas.png'
import record from '../Components/Images/Channels/record.png'
import tvacritica from '../Components/Images/Channels/tvacritica.png'
import redeamazonica from '../Components/Images/Channels/redeamazonica.png'
import tvnorteamazonas from '../Components/Images/Channels/tvnorteamazonas.png'
import redebrasil from '../Components/Images/Channels/redebrasil.png'
import band from '../Components/Images/Channels/band.png'
import redetv from '../Components/Images/Channels/redetv.png'

import tvmilagro from '../Components/Images/Channels/tvmilagro.png'
import terraviva from '../Components/Images/Channels/terraviva.png'
import canaldoboi from '../Components/Images/Channels/canaldoboi.png'
import agrocanal from '../Components/Images/Channels/agrocanal.png'

import animalplanet from '../Components/Images/Channels/animalplanet.png'
import discoverychannel from '../Components/Images/Channels/discoverychannel.png'
import discoveryscience from '../Components/Images/Channels/discoveryscience.png'
import discoveryworld from '../Components/Images/Channels/discoveryworld.png'
import discoverytheater from '../Components/Images/Channels/discoverytheater.png'
import discoveryturbo from '../Components/Images/Channels/discoveryturbo.png'
import discoveryhh from '../Components/Images/Channels/discoveryh&h.png'
import natgeo from '../Components/Images/Channels/natgeo.png'
import history from '../Components/Images/Channels/history.png'
import history2 from '../Components/Images/Channels/history2.png'
import curta from '../Components/Images/Channels/curta.png'
import arte1 from '../Components/Images/Channels/arte1.png'

import aulaemcasa from '../Components/Images/Channels/aulaemcasa.png'
import tvescola from '../Components/Images/Channels/tvescola.png'

import espn from '../Components/Images/Channels/espn.png'
import espn2 from '../Components/Images/Channels/espn2.png'
import espn3 from '../Components/Images/Channels/espn3.png'
import espn4 from '../Components/Images/Channels/espn4.png'
import espnextra from '../Components/Images/Channels/espnextra.png'
import foxsports from '../Components/Images/Channels/foxsports.png'
import bandsports from '../Components/Images/Channels/bandsports.png'
import tvturfe from '../Components/Images/Channels/tvturfe.png'

import iddiscovery from '../Components/Images/Channels/iddiscovery.png'
import fx from '../Components/Images/Channels/fx.png'
import tnt from '../Components/Images/Channels/tnt.png'
import tntseries from '../Components/Images/Channels/tntseries.png'
import tcm from '../Components/Images/Channels/tcm.png'
import tbs from '../Components/Images/Channels/tbs.png'
import space from '../Components/Images/Channels/space.png'
import cinebrasil from '../Components/Images/Channels/cinebrasil.png'
import paramount from '../Components/Images/Channels/paramountchannel.png'
import cinecanal from '../Components/Images/Channels/cinecanal.png'
import warner from '../Components/Images/Channels/warner.png'
import sony from '../Components/Images/Channels/sony.png'
import axn from '../Components/Images/Channels/axn.png'

import tvjustica from '../Components/Images/Channels/tvjustica.png'
import camaramanaus from '../Components/Images/Channels/camaramanaus.png'
import tvassembleia from '../Components/Images/Channels/tvassembleia.png'
import tvcamara from '../Components/Images/Channels/tvcamara.png'
import tvsenado from '../Components/Images/Channels/tvsenado.png'

import disneychannel from '../Components/Images/Channels/disneychannel.png'
import nickjr from '../Components/Images/Channels/nickjr.png'
import nickelodeon from '../Components/Images/Channels/nickelodeon.png'
import cartoonito from '../Components/Images/Channels/cartoonito.png'
import tooncast from '../Components/Images/Channels/tooncast.png'
import zoomookids from '../Components/Images/Channels/zoomookids.png'
import discoverykids from '../Components/Images/Channels/discoverykids.png'
import cartoon from '../Components/Images/Channels/cartoon.png'
import ratimbum from '../Components/Images/Channels/ratimbum.png'

import tiradentes from '../Components/Images/Channels/tiradentes.png'
import mtvlive from '../Components/Images/Channels/mtvlive.png'
import mtv from '../Components/Images/Channels/mtv.png'
import musicbox from '../Components/Images/Channels/musicbox.png'

import recordnews from '../Components/Images/Channels/recordnews.png'
import jovempannews from '../Components/Images/Channels/jovempannews.png'
import cnni from '../Components/Images/Channels/cnni.png'
import cnnbrasil from '../Components/Images/Channels/cnnbrasil.png'
import bandnews from '../Components/Images/Channels/bandnews.png'

import apostolos from '../Components/Images/Channels/12apostolos.png'
import boavontade from '../Components/Images/Channels/boavontade.png'
import redeseculo21 from '../Components/Images/Channels/redeseculo21.png'
import tvpaieterno from '../Components/Images/Channels/tvpaieterno.png'
import boasnovas from '../Components/Images/Channels/boasnovas.png'
import redegenesis from '../Components/Images/Channels/redegenesis.png'
import rit from '../Components/Images/Channels/rit.png'
import tvmundomaior from '../Components/Images/Channels/tvmundomaior.png'
import idealtv from '../Components/Images/Channels/idealtv.png'
import cancaonova from '../Components/Images/Channels/cancaonova.png'
import tvnovotempo from '../Components/Images/Channels/tvnovotempo.png'
import redevida from '../Components/Images/Channels/redevida.png'
import redefamilia from '../Components/Images/Channels/redefamilia.png'
import redesuper from '../Components/Images/Channels/redesuper.png'
import tvaparecida from '../Components/Images/Channels/tvaparecida.png'

import tvcultura from '../Components/Images/Channels/tvcultura.png'
import womptv from '../Components/Images/Channels/womptv.png'
import gazeta from '../Components/Images/Channels/gazeta.png'
import sesctv from '../Components/Images/Channels/sesctv.png'
import canalsaude from '../Components/Images/Channels/canalsaude.png'
import fishtv from '../Components/Images/Channels/fishtv.png'
import lifetime from '../Components/Images/Channels/lifetime.png'
import ae from '../Components/Images/Channels/ae.png'
import e from '../Components/Images/Channels/e.png'
import woohoo from '../Components/Images/Channels/woohoo.png'
import hgtv from '../Components/Images/Channels/hgtv.png'
import travelbox from '../Components/Images/Channels/travelbox.png'
import starchannel from '../Components/Images/Channels/starchannel.png'
import comedycentral from '../Components/Images/Channels/comedycentral.png'
import primebox from '../Components/Images/Channels/primebox.png'
import tripbrasil from '../Components/Images/Channels/tripbrasil.png'
import fashiontv from '../Components/Images/Channels/fashiontv.png'
import saborearte from '../Components/Images/Channels/saborearte.png'
import tlc from '../Components/Images/Channels/tlc.png'

import x9xm from '../Components/Images/Channels/9xm.png'
import tv360tv from '../Components/Images/Channels/360tv.png'
import abctv from '../Components/Images/Channels/abctv.png'
import adnkronos from '../Components/Images/Channels/adnkronos.png'
import alcancetv from '../Components/Images/Channels/alcancetv.png'
import almeretv from '../Components/Images/Channels/almeretv.png'
import altenburgtv from '../Components/Images/Channels/altenburgtv.png'
import angeltv from '../Components/Images/Channels/angeltv.png'
//import ariananews from '../Components/Images/Channels/ariananews.png'
//import artvcanalparlamento from '../Components/Images/Channels/artvcanalparlamento.png'
import atomicacademytv from '../Components/Images/Channels/atomicacademytv.png'
import ausbiztv from '../Components/Images/Channels/ausbiztv.png'
import avffamily from '../Components/Images/Channels/avffamily.png'
//import canalcapital from '../Components/Images/Channels/canalcapital.png'
import channel86 from '../Components/Images/Channels/channel86.png'
import estrellagames from '../Components/Images/Channels/estrellagames.png'
import estrellanews from '../Components/Images/Channels/estrellanews.png'
//import exitosa from '../Components/Images/Channels/exitosa.png'
//import notele from '../Components/Images/Channels/notele.png'
import ntvnews from '../Components/Images/Channels/ntvnews.png'
import purapalabra from '../Components/Images/Channels/purapalabra.png'
import redbultv from '../Components/Images/Channels/redbultv.png'
import rtasports from '../Components/Images/Channels/rtasports.png'
//import tickernews from '../Components/Images/Channels/tickernews.png'
import tv2nord from '../Components/Images/Channels/tv2nord.png'
import tvmidtvest from '../Components/Images/Channels/tvmidtvest.png'
import twentetv from '../Components/Images/Channels/twentetv.png'
import vmlatino from '../Components/Images/Channels/vmlatino.png'
import wtvcanal20 from '../Components/Images/Channels/wtvcanal20.png'
import avivamiento from '../Components/Images/Channels/avivamiento.png'
import bloombergtv from '../Components/Images/Channels/bloombergtv.png'
import bvc from '../Components/Images/Channels/bvc.png'
import cts from '../Components/Images/Channels/cts.png'
//import educatv from '../Components/Images/Channels/educatv.png'
import kct from '../Components/Images/Channels/kct.png'
import mbcbollywood from '../Components/Images/Channels/mbcbollywood.png'
//import multicanalcatamayo from '../Components/Images/Channels/multicanalcatamayo.png'
import nabaatv from '../Components/Images/Channels/nabaatv.png'
//import nettv from '../Components/Images/Channels/nettv.png'
import nhkworld from '../Components/Images/Channels/nhkworld.png'
import polishop from '../Components/Images/Channels/polishop.png'
import samiratv from '../Components/Images/Channels/samiratv.png'
import somalinationaltv from '../Components/Images/Channels/somalinationaltv.png'
//import stv2hd from '../Components/Images/Channels/stv2hd.png'
import tbsgringa from '../Components/Images/Channels/tbsgringa.png'
//import xfn from '../Components/Images/Channels/xfn.png'
import abn3abn from '../Components/Images/Channels/3abn.png'
//import a3 from '../Components/Images/Channels/a3.png'
import alIraqiyaal3ama from '../Components/Images/Channels/alIraqiyaal3ama.png'
import aljazeeraarabe from '../Components/Images/Channels/aljazeeraarabe.png'
import alpenlandtv from '../Components/Images/Channels/alpenlandtv.png'
import arirangkorea from '../Components/Images/Channels/arirangkorea.png'
import auroraarte from '../Components/Images/Channels/auroraarte.png'
import byoblu from '../Components/Images/Channels/byoblu.png'
import calabriatv from '../Components/Images/Channels/calabriatv.png'
//import canalalphajura from '../Components/Images/Channels/canalalphajura.png'
//import canalmalaga from '../Components/Images/Channels/canalmalaga.png'

export default function Canais() {
  return (
    <div className="App">
      <Header />
      <div
        style={{
          background: 'linear-gradient(to left, #0E0326, #2D1A57)',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '8em',
          paddingBottom: '5em'
        }}
      >
        <div class="div-title1">
          <p class="text-title1">Absolutamente tudo</p>
          <p class="text-sub">na sua TV e na palma</p>
          <p class="text-sub">da sua mão</p>
        </div>
        <div>
          <div class="div-full">
            <div class="div-planos">
              <button class="button-planos">
                <img class="image-planos" src={Star} alt="Descrição da imagem" />
              </button>
              <button class="button-planos">
                <img class="image-planos" src={Full} alt="Descrição da imagem" />
              </button>
            </div>
            <div>
              <p class="text-plan">CANAIS ABERTOS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={tvencontrodasaguas} alt="" />
                <p class="img-text">CANAL 2</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={record} alt="" />
                <p class="img-text">CANAL 3</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvacritica} alt="" />
                <p class="img-text">CANAL 4</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redeamazonica} alt="" />
                <p class="img-text">CANAL 6</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvnorteamazonas} alt="" />
                <p class="img-text">CANAL 7</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redebrasil} alt="" />
                <p class="img-text">CANAL 8</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={band} alt="" />
                <p class="img-text">CANAL 9</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redetv} alt="" />
                <p class="img-text">CANAL 11</p>
              </div>
            </div>
            <div>
              <p class="text-plan">AGRO</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={tvmilagro} alt="" />
                <p class="img-text">CANAL 75</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={terraviva} alt="" />
                <p class="img-text">CANAL 76</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={canaldoboi} alt="" />
                <p class="img-text">CANAL 77</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={agrocanal} alt="" />
                <p class="img-text">CANAL 78</p>
              </div>
            </div>
            <div>
              <p class="text-plan">DOCUMENTÁRIOS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={animalplanet} alt="" />
                <p class="img-text">CANAL 130</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={discoverychannel} alt="" />
                <p class="img-text">CANAL 131</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={discoveryscience} alt="" />
                <p class="img-text">CANAL 132</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={discoveryworld} alt="" />
                <p class="img-text">CANAL 133</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={discoverytheater} alt="" />
                <p class="img-text">CANAL 134</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={discoveryturbo} alt="" />
                <p class="img-text">CANAL 135</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={discoveryhh} alt="" />
                <p class="img-text">CANAL 136</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={natgeo} alt="" />
                <p class="img-text">CANAL 138</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={history} alt="" />
                <p class="img-text">CANAL 139</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={history2} alt="" />
                <p class="img-text">CANAL 140</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={curta} alt="" />
                <p class="img-text">CANAL 142</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={arte1} alt="" />
                <p class="img-text">CANAL 143</p>
              </div>
            </div>
            <div>
              <p class="text-plan">E COMMERCE</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={polishop} alt="" />
                <p class="img-text">CANAL 70</p>
              </div>
            </div>
            <div>
              <p class="text-plan">EDUCATIVOS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={aulaemcasa} alt="" />
                <p class="img-text">CANAL 40</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={aulaemcasa} alt="" />
                <p class="img-text">CANAL 41</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={aulaemcasa} alt="" />
                <p class="img-text">CANAL 42</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvescola} alt="" />
                <p class="img-text">CANAL 43</p>
              </div>
            </div>
            <div>
              <p class="text-plan">ESPORTES</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={espn} alt="" />
                <p class="img-text">CANAL 100</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={espn2} alt="" />
                <p class="img-text">CANAL 101</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={espn3} alt="" />
                <p class="img-text">CANAL 102</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={espn4} alt="" />
                <p class="img-text">CANAL 103</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={espnextra} alt="" />
                <p class="img-text">CANAL 104</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={foxsports} alt="" />
                <p class="img-text">CANAL 105</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={bandsports} alt="" />
                <p class="img-text">CANAL 106</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvturfe} alt="" />
                <p class="img-text">CANAL 107</p>
              </div>
            </div>
            <div>
              <p class="text-plan">FILMES E SÉRIES</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={iddiscovery} alt="" />
                <p class="img-text">CANAL 137</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={fx} alt="" />
                <p class="img-text">CANAL 150</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tnt} alt="" />
                <p class="img-text">CANAL 151</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tntseries} alt="" />
                <p class="img-text">CANAL 152</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tcm} alt="" />
                <p class="img-text">CANAL 153</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tbs} alt="" />
                <p class="img-text">CANAL 154</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={space} alt="" />
                <p class="img-text">CANAL 155</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cinebrasil} alt="" />
                <p class="img-text">CANAL 156</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={paramount} alt="" />
                <p class="img-text">CANAL 157</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cinecanal} alt="" />
                <p class="img-text">CANAL 158</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={warner} alt="" />
                <p class="img-text">CANAL 159</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={sony} alt="" />
                <p class="img-text">CANAL 160</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={axn} alt="" />
                <p class="img-text">CANAL 161</p>
              </div>
            </div>
            <div>
              <p class="text-plan">GOVERNAMENTAIS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={tvjustica} alt="" />
                <p class="img-text">CANAL 50</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={camaramanaus} alt="" />
                <p class="img-text">CANAL 51</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvassembleia} alt="" />
                <p class="img-text">CANAL 52</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvcamara} alt="" />
                <p class="img-text">CANAL 53</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvsenado} alt="" />
                <p class="img-text">CANAL 54</p>
              </div>
            </div>
            <div>
              <p class="text-plan">INFANTIS E ANIMAÇÃO</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={disneychannel} alt="" />
                <p class="img-text">CANAL 85</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={nickjr} alt="" />
                <p class="img-text">CANAL 86</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={nickelodeon} alt="" />
                <p class="img-text">CANAL 87</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cartoonito} alt="" />
                <p class="img-text">CANAL 88</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tooncast} alt="" />
                <p class="img-text">CANAL 89</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={zoomookids} alt="" />
                <p class="img-text">CANAL 90</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={discoverykids} alt="" />
                <p class="img-text">CANAL 91</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cartoon} alt="" />
                <p class="img-text">CANAL 92</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={ratimbum} alt="" />
                <p class="img-text">CANAL 93</p>
              </div>
            </div>
            <div>
              <p class="text-plan">MÚSICAS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={tiradentes} alt="" />
                <p class="img-text">CANAL 20</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={mtvlive} alt="" />
                <p class="img-text">CANAL 65</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={mtv} alt="" />
                <p class="img-text">CANAL 66</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={musicbox} alt="" />
                <p class="img-text">CANAL 67</p>
              </div>
            </div>
            <div>
              <p class="text-plan">NOTÍCIAS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={recordnews} alt="" />
                <p class="img-text">CANAL 16</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={jovempannews} alt="" />
                <p class="img-text">CANAL 17</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cnnbrasil} alt="" />
                <p class="img-text">CANAL 170</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cnni} alt="" />
                <p class="img-text">CANAL 171</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={bandnews} alt="" />
                <p class="img-text">CANAL 172</p>
              </div>
            </div>
            <div>
              <p class="text-plan">RELIGIOSOS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={apostolos} alt="" />
                <p class="img-text">CANAL 12</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={boavontade} alt="" />
                <p class="img-text">CANAL 21</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redeseculo21} alt="" />
                <p class="img-text">CANAL 22</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvpaieterno} alt="" />
                <p class="img-text">CANAL 23</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={boasnovas} alt="" />
                <p class="img-text">CANAL 24</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redegenesis} alt="" />
                <p class="img-text">CANAL 25</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={rit} alt="" />
                <p class="img-text">CANAL 26</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvmundomaior} alt="" />
                <p class="img-text">CANAL 27</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={idealtv} alt="" />
                <p class="img-text">CANAL 28</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cancaonova} alt="" />
                <p class="img-text">CANAL 29</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvnovotempo} alt="" />
                <p class="img-text">CANAL 30</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redevida} alt="" />
                <p class="img-text">CANAL 31</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redefamilia} alt="" />
                <p class="img-text">CANAL 32</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redesuper} alt="" />
                <p class="img-text">CANAL 33</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvaparecida} alt="" />
                <p class="img-text">CANAL 34</p>
              </div>
            </div>
            <div>
              <p class="text-plan">VARIEDADES</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={tvcultura} alt="" />
                <p class="img-text">CANAL 01</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={womptv} alt="" />
                <p class="img-text">CANAL 05</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={gazeta} alt="" />
                <p class="img-text">CANAL 19</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={sesctv} alt="" />
                <p class="img-text">CANAL 60</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={canalsaude} alt="" />
                <p class="img-text">CANAL 61</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={fishtv} alt="" />
                <p class="img-text">CANAL 108</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={lifetime} alt="" />
                <p class="img-text">CANAL 117</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={ae} alt="" />
                <p class="img-text">CANAL 118</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={e} alt="" />
                <p class="img-text">CANAL 119</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={woohoo} alt="" />
                <p class="img-text">CANAL 120</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={hgtv} alt="" />
                <p class="img-text">CANAL 121</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={travelbox} alt="" />
                <p class="img-text">CANAL 122</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={starchannel} alt="" />
                <p class="img-text">CANAL 123</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={comedycentral} alt="" />
                <p class="img-text">CANAL 124</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={primebox} alt="" />
                <p class="img-text">CANAL 125</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tripbrasil} alt="" />
                <p class="img-text">CANAL 126</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={fashiontv} alt="" />
                <p class="img-text">CANAL 127</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={saborearte} alt="" />
                <p class="img-text">CANAL 128</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 129</p>
              </div>
            </div>
            <div>
              <p class="text-plan">INTERNACIONAIS</p>
            </div>
            <div class="div-logo-plan">
              <div class="img-container">
                <img class="img-logo-plan" src={byoblu} alt="" />
                <p class="img-text">CANAL 351</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={nhkworld} alt="" />
                <p class="img-text">CANAL 352</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 353</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={alIraqiyaal3ama} alt="" />
                <p class="img-text">CANAL 355</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tbsgringa} alt="" />
                <p class="img-text">CANAL 357</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 358</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={arirangkorea} alt="" />
                <p class="img-text">CANAL 359</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={cts} alt="" />
                <p class="img-text">CANAL 360</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={abn3abn} alt="" />
                <p class="img-text">CANAL 361</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={aljazeeraarabe} alt="" />
                <p class="img-text">CANAL 362</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={mbcbollywood} alt="" />
                <p class="img-text">CANAL 363</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={samiratv} alt="" />
                <p class="img-text">CANAL 364</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={nabaatv} alt="" />
                <p class="img-text">CANAL 365</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={alpenlandtv} alt="" />
                <p class="img-text">CANAL 366</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={calabriatv} alt="" />
                <p class="img-text">CANAL 368</p>
              </div>

              <div class="img-container">
                <img class="img-logo-plan" src={somalinationaltv} alt="" />
                <p class="img-text">CANAL 370</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={auroraarte} alt="" />
                <p class="img-text">CANAL 371</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={avivamiento} alt="" />
                <p class="img-text">CANAL 372</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={kct} alt="" />
                <p class="img-text">CANAL 374</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={bvc} alt="" />
                <p class="img-text">CANAL 378</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={bloombergtv} alt="" />
                <p class="img-text">CANAL 379</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={avffamily} alt="" />
                <p class="img-text">CANAL 381</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={vmlatino} alt="" />
                <p class="img-text">CANAL 382</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={x9xm} alt="" />
                <p class="img-text">CANAL 383</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={atomicacademytv} alt="" />
                <p class="img-text">CANAL 384</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={alcancetv} alt="" />
                <p class="img-text">CANAL 387</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 388</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tv2nord} alt="" />
                <p class="img-text">CANAL 389</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={estrellagames} alt="" />
                <p class="img-text">CANAL 390</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={estrellanews} alt="" />
                <p class="img-text">CANAL 391</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tvmidtvest} alt="" />
                <p class="img-text">CANAL 392</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={purapalabra} alt="" />
                <p class="img-text">CANAL 393</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 394</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={altenburgtv} alt="" />
                <p class="img-text">CANAL 395</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={ausbiztv} alt="" />
                <p class="img-text">CANAL 396</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={abctv} alt="" />
                <p class="img-text">CANAL 397</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={angeltv} alt="" />
                <p class="img-text">CANAL 399</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 400</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tv360tv} alt="" />
                <p class="img-text">CANAL 401</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={almeretv} alt="" />
                <p class="img-text">CANAL 402</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={ntvnews} alt="" />
                <p class="img-text">CANAL 403</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={adnkronos} alt="" />
                <p class="img-text">CANAL 404</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={redbultv} alt="" />
                <p class="img-text">CANAL 405</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={channel86} alt="" />
                <p class="img-text">CANAL 406</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={twentetv} alt="" />
                <p class="img-text">CANAL 407</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={rtasports} alt="" />
                <p class="img-text">CANAL 408</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={wtvcanal20} alt="" />
                <p class="img-text">CANAL 410</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 411</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 412</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 414</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 416</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 417</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 418</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 419</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 423</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 424</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 425</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 426</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 427</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 428</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 429</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 430</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 431</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 432</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 433</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 434</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 435</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 436</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 437</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 438</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 439</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 440</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 441</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 442</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 443</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 444</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 445</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 446</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 447</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 448</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 449</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 450</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 451</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 452</p>
              </div>
              <div class="img-container">
                <img class="img-logo-plan" src={tlc} alt="" />
                <p class="img-text">CANAL 453</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Aqui você pode adicionar o QR code ou outras informações relacionadas aos canais */}
      <Questions />
      <Footer />
    </div>
  )
}
