import * as React from 'react'
import Carousel from 'react-elastic-carousel'
import Item from './Item'
import './styles.css'
import whatsapp from '../Images/whatslogo.png'

import womptv from '../Images/Channels/womptv.png'
import apostolos from '../Images/Channels/12apostolos.png'
import redeamazonica from '../Images/Channels/redeamazonica.png'
import tvnorteamazonas from '../Images/Channels/tvnorteamazonas.png'
import band from '../Images/Channels/band.png'
import record from '../Images/Channels/record.png'
import tvacritica from '../Images/Channels/tvacritica.png'
import jovempannews from '../Images/Channels/jovempannews.png'
import sesctv from '../Images/Channels/sesctv.png'
import animalplanet from '../Images/Channels/animalplanet.png'
import cartoon from '../Images/Channels/cartoon.png'
import cnni from '../Images/Channels/cnni.png'
import espn from '../Images/Channels/espn.png'
import foxsports from '../Images/Channels/foxsports.png'
import disneychannel from '../Images/Channels/disneychannel.png'
import mtv from '../Images/Channels/mtv.png'
import natgeo from '../Images/Channels/natgeo.png'
import nickelodeon from '../Images/Channels/nickelodeon.png'
import tnt from '../Images/Channels/tnt.png'

import cartoonito from '../Images/Channels/cartoonito.png'
import curta from '../Images/Channels/curta.png'
import comedycentral from '../Images/Channels/comedycentral.png'
import discoverychannel from '../Images/Channels/discoverychannel.png'
import discoverykids from '../Images/Channels/discoverykids.png'
import paramountchannel from '../Images/Channels/paramountchannel.png'
import primeboxbrasil from '../Images/Channels/primeboxbrasil.png'
import sony from '../Images/Channels/sony.png'
import starchannel from '../Images/Channels/starchannel.png'
import warner from '../Images/Channels/warner.png'

import star from '../Images/star_500x500.png'
import full from '../Images/full_500x500.png'
import blue from '../Images/icon_correct_wompplay_150x150.png'

const breakPoints = [
  { width: 150, itemsToShow: 1, itemsToScroll: 1 },
  { width: 180, itemsToShow: 1, itemsToScroll: 1 },
  { width: 500, itemsToShow: 2, itemsToScroll: 1 },
  { width: 768, itemsToShow: 2, itemsToScroll: 1 },
  { width: 900, itemsToShow: 2, itemsToScroll: 1 },
  { width: 1200, itemsToShow: 2, itemsToScroll: 1 }
]

const breakPoints1 = [
  { width: 150, itemsToShow: 2, itemsToScroll: 1 },
  { width: 180, itemsToShow: 3, itemsToScroll: 1 },
  { width: 500, itemsToShow: 4, itemsToScroll: 1 },
  { width: 768, itemsToShow: 6, itemsToScroll: 1 },
  { width: 1200, itemsToShow: 8, itemsToScroll: 1 }
]

export default function Planos() {
  return (
    <div
      style={{
        background: 'linear-gradient(to left, #0E0326, #2D1A57)',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Carousel
        className="custom-carousel"
        breakPoints={breakPoints}
        /* -- TRANSITION ANIMADA
        easing="cubic-bezier(1,.15,.55,1.54)"
        tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
        transitionMs={700}
        */
        enableAutoPlay={false}
        autoPlaySpeed={10000}
        pagination={false}
      >
        <Item className="plan-star carousel-item">
          <img class="img-p0" src={star} alt="" />
          <div className="div-info-pagamento">
            <div>
              <p class="text-sub-valor">POR</p>
              <p className="text-sub-valor">APENAS</p>
              <p className="text-cifra-valor">R$</p>
            </div>
            <div>
              <p className="text-title-valor">19</p>
            </div>
            <div>
              <p className="text-cents-valor">,99</p>
              <p className="text-time-valor">/MÊS</p>
            </div>
          </div>
          <div className="div-info-title"></div>
          <div className="div-info-p1">
            <img src={blue} alt="Descrição da imagem" class="icon" />
            <p className="text-p-title">120 Canais</p>
          </div>
          <div className="div-info-p">
            <p className="text-p-info">
              Canais abertos, filmes, séries, esportes, entretenimento e muito
              mais.
            </p>
          </div>
          <div className="div-info-p">
            <img src={blue} alt="Descrição da imagem" class="icon" />
            <p className="text-p-info">
              Assista em até 2 dispositivos simultâneos
            </p>
          </div>
          <div className="div-info-p">
            <img src={blue} alt="Descrição da imagem" class="icon" />
            <p className="text-p-info">Fidelidade de 12 meses</p>
          </div>
          {/* 
          <a
            className="Assinar"
            href="https://checkout.wompplay.com/"
            target="_blank"
            rel="noreferrer"
            onClick={event => {
              event.preventDefault() // Impede o comportamento padrão do link
            }}
          >
            SAIBA MAIS
          </a>
          */}
        </Item>
        <Item className="plan-full carousel-item">
          <img class="img-p0" src={full} alt="" />
          <div className="div-info-pagamento">
            <div>
              <p class="text-sub-valor">POR</p>
              <p className="text-sub-valor">APENAS</p>
              <p className="text-cifra-valor">R$</p>
            </div>
            <div>
              <p className="text-title-valor">139</p>
            </div>
            <div>
              <p className="text-cents-valor">,99</p>
              <p className="text-time-valor">/MÊS</p>
            </div>
          </div>
          <div className="div-info-title"></div>
          <div className="div-info-p1">
            <img src={blue} alt="Descrição da imagem" class="icon" />
            <p className="text-p-title">185 Canais</p>
          </div>
          <div className="div-info-p">
            <p className="text-p-info">
              Canais abertos, filmes, séries, esportes, entretenimento e muito
              mais.
            </p>
          </div>
          <div className="div-info-p">
            <img src={blue} alt="Descrição da imagem" class="icon" />
            <p className="text-p-info">
              Assista em até 3 dispositivos simultâneos
            </p>
          </div>
          <div className="div-info-p">
            <img src={blue} alt="Descrição da imagem" class="icon" />
            <p className="text-p-info">Fidelidade de 12 meses</p>
          </div>
          {/*
          <a
            className="Assinar"
            href="https://checkout.wompplay.com/"
            target="_blank"
            rel="noreferrer"
            onClick={event => {
              event.preventDefault() // Impede o comportamento padrão do link
            }}
          >
            SAIBA MAIS
          </a>
        */}
        </Item>
      </Carousel>
      <Item style={{ marginTop: '4rem' }}>
        <a
          class="p-assine"
          href="https://checkout.wompplay.com/"
          target="_blank"
          rel="noreferrer"
        >
          ASSINE AGORA
        </a>
      </Item>
      <div
        style={{
          marginTop: '4rem',
          marginBottom: '4rem'
        }}
      >
        <p class="text-title1">Os melhores canais</p>
        <p class="text-sub">a melhor programação</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Carousel
          breakPoints={breakPoints1}
          /* -- TRANSITION ANIMADA */
          easing="cubic-bezier(0.645, 0.045, 0.355, 1)" // Easing suave
          tiltEasing="cubic-bezier(0.645, 0.045, 0.355, 1)" // Easing suave
          transitionMs={1000} // Duração da transição em milissegundos
          enableAutoPlay={true}
          autoPlaySpeed={6000}
          pagination={false}
          itemsToScroll={1}
          infinite={true} // Habilita o looping
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#0E0326'
          }}
        >
          <div class="StyledChannels">
            <img src={womptv} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={apostolos} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={redeamazonica} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img
              src={tvnorteamazonas}
              alt="Descrição da imagem"
              class="Imagem"
            />
          </div>
          <div class="StyledChannels">
            <img src={band} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={tvacritica} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={record} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={jovempannews} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={sesctv} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={animalplanet} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={cartoon} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={cnni} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={disneychannel} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={espn} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={foxsports} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={mtv} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={natgeo} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={nickelodeon} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={tnt} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={cartoonito} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={curta} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={comedycentral} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img
              src={discoverychannel}
              alt="Descrição da imagem"
              class="Imagem"
            />
          </div>
          <div class="StyledChannels">
            <img src={discoverykids} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img
              src={paramountchannel}
              alt="Descrição da imagem"
              class="Imagem"
            />
          </div>
          <div class="StyledChannels">
            <img
              src={primeboxbrasil}
              alt="Descrição da imagem"
              class="Imagem"
            />
          </div>
          <div class="StyledChannels">
            <img src={sony} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={starchannel} alt="Descrição da imagem" class="Imagem" />
          </div>
          <div class="StyledChannels">
            <img src={warner} alt="Descrição da imagem" class="Imagem" />
          </div>
        </Carousel>
      </div>
      <div
        style={{
          paddingBottom: 100
        }}
      >
        <div class="contato">
          <text class="text-title1">Contato</text>
        </div>
        <div>
          <a
            href="https://wa.me/+559240209933?text=Tenho%20interesse%20em%20comprar%20o%20seu%20produto"
            target="_blank"
            class="ddd-number"
            rel="noreferrer"
          >
            <img class="whatsapp-number" src={whatsapp} alt="" />
            92
          </a>
          <a
            href="https://wa.me/+559240209933?text=Tenho%20interesse%20em%20comprar%20o%20seu%20produto"
            target="_blank"
            class="text-number"
            rel="noreferrer"
          >
            4020-9933
          </a>
        </div>
        <p class="pcontato">comercial@womp.com.br</p>
      </div>
    </div>
  )
}
