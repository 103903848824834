import React from 'react'
import Header from '../Components/Header/Header.js'
import Questions from '../Components/Questions/Questions.js'
import Footer from '../Components/Footer/Footer.js'

import lupa from '../Components/Images/lupa.png'

export default function Ajuda() {
  return (
    <div className="App">
      <Header />
      <div
        style={{
          background: 'linear-gradient(to left, #0E0326, #2D1A57)',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '8em',
          paddingBottom: '5em'
        }}
      >
        <div class="div-title1">
          <p class="text-title1">Em que podemos lhe ajudar?</p>
        </div>
        <div class="div-input-ajuda">
          <div class="div-ajuda">
            <img class="img-lupa" src={lupa} alt="" />
            <input
              type="text"
              class="text-form-input-ajuda"
              placeholder="Pesquisar tema..."
            />
          </div>
        </div>
      </div>

      <Questions />
      <Footer />
    </div>
  )
}
