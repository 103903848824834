import React from 'react'
import { Fragment } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from '../Home/Home.js'
import Canais from '../../Pages/canais.js'
import Plans from '../../Pages/plans.js'
import Dispositivos from '../../Pages/dispositivos'
import Contato from '../../Pages/contato'
import Ajuda from '../../Pages/ajuda'

import Termos from '../../Pages/termos.js'
import Privacidade from '../../Pages/Privacidade.js'
import Cookies from '../../Pages/cookies.js'

function Rotas() {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/canais" element={<Canais />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/dispositivos" element={<Dispositivos />} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/ajuda" element={<Ajuda />} />
          <Route path="/privacidade" element={<Privacidade />} />
          <Route path="/termos-de-uso" element={<Termos />} />
          <Route path="/cookies" element={<Cookies />} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  )
}

export default Rotas
