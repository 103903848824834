import React from 'react'
import Header from '../Components/Header/Header.js'
import PlanosA from '../Components/PlanosA/PlanosA.js'
import Questions from '../Components/Questions/Questions.js'
import Footer from '../Components/Footer/Footer.js'

export default function Plans() {
  return (
    <div className="App">
      <Header />
      <div
        style={{
          background: 'linear-gradient(to left, #0E0326, #2D1A57)',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '8em',
          paddingBottom: '5em'
        }}
      >
        <div class="div-title1">
          <p class="text-sub">confira nossos</p>
          <p class="text-title1">pacotes de canais</p>
        </div>
      </div>
      <PlanosA />
      <Questions />
      <Footer />
    </div>
  )
}
